<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="danger" @click="createdItem">新 增</el-button>
      <el-input v-model="tableQuery.name" style="width: 200px;margin: 0 10px" placeholder="请输入名称"></el-input>
      <el-button :loading="tableLoading" type="danger" @click="selectRolePage">搜 索</el-button>
    </div>
    <el-table
        :data="tableData" class="table" height="560px"
        row-key="id" border v-loading="tableLoading">
      <el-table-column prop="id" label="ID" width="160"/>
      <el-table-column prop="name" label="名称" width="200"/>
      <el-table-column prop="createTime" label="创建时间" width="220"/>
      <el-table-column prop="remark" label="备注说明"/>
      <el-table-column label="操作" width="110" align="center">
        <template #default="scope">
          <div style="margin-right: 4px;">
            <el-button type="text" size="small" @click.prevent="updateItem(scope.row)">编辑</el-button>
            <el-button type="text" size="small" style="color: red" @click.prevent="deleteItem(scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: right;margin: 20px 10px"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length">
    </el-pagination>
    <created-role @refresh="selectRolePage"  ref="created"/>
    <update-role @refresh="selectRolePage" ref="update"/>
  </div>
</template>

<script>
import '/src/assets/styles.css';
import {selectRolePage,selectRoleGroup} from '/src/api/contacts/role'
import CreatedRole from "./CreatedRole";
import UpdateRole from "./UpdateRole";
export default {
  name: "Role",
  components:{CreatedRole,UpdateRole},
  data() {
    return {
      tableQuery:{
        name:""
      },
      tableData:[],
      roleGroup:[],
      tableLoading:false,
    }
  },
  methods:{
    selectRolePage(){
      this.tableLoading = true;
      selectRolePage(this.tableQuery).then(val => {
        this.tableLoading = false;
        if (val.status){
          this.tableData = val.data;
        }
      })
    },
    createdItem(){
      this.$refs.created.openDialog(this.roleGroup);
    },
    updateItem(item){
      this.$refs.update.openDialog(item,this.roleGroup);
    },
    deleteItem(){
      this.$message.error('暂不支持删除！');
    }
  },
  created() {
    this.selectRolePage();
    selectRoleGroup().then(val => {
      if (val.status){
        this.roleGroup = val.data;
      }
    })
  }
}
</script>

<style scoped>
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 0px 10px 6px;
}

</style>