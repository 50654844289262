import request from '/src/api/request.js'
// 查询角色
export function selectRole() {
    return request({
        url: 'sys/role/selectRole',
        method: 'get'
    })
}
/**
 * 查询角色组
 */
export function selectRoleGroup() {
    return request({
        url: 'sys/role/selectRoleGroup',
        method: 'get'
    })
}
/**
 * 角色管理查询角色列表
 */
export function selectRolePage(query) {
    return request({
        url: 'sys/role/selectRolePage',
        method: 'POST',
        data: query,
    })
}
/**
 * 新增角色组或者角色
 */
export function createRole(item) {
    return request({
        url: 'sys/role/createRole',
        method: 'POST',
        data: item,
    })
}

/**
 * 更新角色组或者角色
 */
export function updateRole(item) {
    return request({
        url: 'sys/role/updateRole',
        method: 'POST',
        data: item,
    })
}
export default {selectRole,selectRolePage}