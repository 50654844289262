<template>
  <div>
    <el-dialog
        title="更新角色"
        :visible.sync="updateDialog"
        width="720px">
      <div style="padding-right: 16px">
        <el-form :model="role" :rules="Roles" ref="Roles" label-width="120px" class="demo-ruleForm">
          <el-row>
            <el-col :span="11">
              <el-form-item label="名称：" prop="name">
                <el-input v-model="role.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="分组：">
                <el-select :disabled="role.children" v-model="role.parentId" placeholder="请选择">
                  <el-option
                      v-for="item in tableList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="备注：">
                <el-input type="textarea" placeholder="请输入内容" v-model="role.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="updateDialog = false">取 消</el-button>
    <el-button type="danger" @click="updateRole">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {updateRole} from "../../../../api/contacts/role";

export default {
  name: "UpdateRole",
  data() {
    return {
      updateDialog:false,
      role:{
        name:"",
        parentId: null,
        remark: "",
      },
      Roles: {
        name: [{
          required: true,
          message: '请输入名称！',
          trigger: 'blur'
        },],
      },
      tableList:[]
    }
  },
  methods:{
    openDialog(item,list) {
      this.role = item;
      this.tableList = list;
      this.updateDialog = true;
    },
    updateRole(){
      this.$refs.Roles.validate((valid)=>{
        if(valid){
          updateRole(this.role).then(val => {
            if (val.data > 0){
              this.$emit('refresh');
              this.updateDialog = false;
              this.$message({message: '更新成功', type: 'success'});
              Object.keys(this.role).forEach((key) => (this.role[key] = null));
            }else {
              this.$message.error('更新失败！');
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>