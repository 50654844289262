<template>
  <div>
    <el-dialog
        title="新增角色"
        :visible.sync="createDialog"
        width="720px">
     <div style="padding-right: 16px">
       <el-form :model="role" :rules="Roles" ref="Roles" label-width="120px" class="demo-ruleForm">
         <el-row>
           <el-col :span="11">
             <el-form-item label="名称：" prop="name">
               <el-input v-model="role.name" placeholder="请输入"></el-input>
             </el-form-item>
           </el-col>
           <el-col :span="11">
             <el-form-item label="分组：">
               <el-select v-model="role.parentId" clearable  placeholder="请选择">
                 <el-option
                     v-for="item in tableList"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
                 </el-option>
               </el-select>
             </el-form-item>
           </el-col>
         </el-row>
         <el-row>
           <el-col :span="22">
             <el-form-item label="备注：">
               <el-input type="textarea" placeholder="请输入内容" v-model="role.remark">
               </el-input>
             </el-form-item>
           </el-col>
         </el-row>
       </el-form>
       <div style="font-size: 10px;height: 20px;color: #ff4d4f">分组选项：如果要新增角色组，请不选择内容。</div>
     </div>
     <span slot="footer" class="dialog-footer">
    <el-button @click="createDialog = false">取 消</el-button>
    <el-button type="danger" @click="createRole">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {createRole} from '/src/api/contacts/role'
export default {
  name: "createdRole",
  data() {
    return {
      createDialog:false,
      role:{
        name:"",
        parentId: null,
        remark: "",
      },
      Roles: {
        name: [{
          required: true,
          message: '请输入名称！',
          trigger: 'blur'
        },],
      },
      tableList:[]
    }
  },
  methods:{
    openDialog(list) {
      this.tableList = list;
      this.createDialog = true;
    },
    createRole(){
      this.$refs.Roles.validate((valid)=>{
        if(valid){
          createRole(this.role).then(val => {
            if (val.data > 0){
              this.$emit('refresh');
              this.createDialog = false;
              this.$message({message: '创建成功', type: 'success'});
              Object.keys(this.role).forEach((key) => (this.role[key] = null));
            }else {
              this.$message.error('创建失败！');
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
/* 使用深度选择器修改聚焦时的边框颜色 */
::v-deep .el-textarea__inner:focus {
  border-color:#ff4d4f; /* 将聚焦时的边框颜色设置为橙色 */
}
</style>